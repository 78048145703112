import axios from "@/axios";
import loader from "@/components/loader";
import Button from "@/components/button";
import SubHeader from "@/components/SubHeader";
import { ENTITY_TYPES_DROPDOWN_CONFIG, DEFAULT_PACKAGE_ENTITY_TYPE } from "@shared/utils/constants";
import { mapActions, mapState } from 'vuex';

export default {
    name: "create-package",
    components: {
        Button,
        axios,
        loader,
        SubHeader,
    },
    async mounted(){
        await this.fetchEntityTypeOptions();
        this.setDefaultEntityType();
    }, 
    title: 'Create Package',

    data() {
        return {
            ENTITY_TYPES_DROPDOWN_CONFIG,
            packageCreationInProgress:false,
            package:{
                package_name:'',
                package_name_internal:'',
                package_entity_type: '',
                entity_types: [],
                package_description:'',
            },
            options: ['Company', 'Person'],
            placeholder: "Enter entity type",
            isEntityTypesLoading: false,
        };
    },
    computed: {
        ...mapState({
            entityTypeOptions: 'entityTypeList',
        }),
        // consider fixed values:
        computedEntityTypeOptions() {
            return this.entityTypeOptions.map((el) => {
                const isPreselected = el[DEFAULT_PACKAGE_ENTITY_TYPE.key] === DEFAULT_PACKAGE_ENTITY_TYPE.value;
                return {
                    ...el,
                    $isDisabled: isPreselected,
                    preselected: isPreselected,
                }
            });
        },
    },
    methods: {
        ...mapActions([
            'fetchEntityTypeList',
        ]),
        async createPackage(){
            this.package.package_name = this.package.package_name.trim();
            try{
                let url = `/screening-package`;
                this.packageCreationInProgress = true
                const payload = {
                    ...this.package,
                    entity_types: this.package.entity_types.map((el) => ({ entity_type_id: el.id }))
                }
                await axios.post(url, payload);
                this.$toast.success("Package created");
                this.$router.push(
                    {
                        "name":"Screening Package"
                    }
                )
            }
            catch(error){
                this.$toast.warning(error.response.data.detail  ,"Could not create package!");
            }

            this.packageCreationInProgress = false
        },
        async fetchEntityTypeOptions() {
            this.isEntityTypesLoading = true;
            if (!this.entityTypeOptions.length) {
                await this.fetchEntityTypeList();
            }
            this.isEntityTypesLoading = false;
        },
        setDefaultEntityType() {
            const preselectedOption = this.computedEntityTypeOptions.find((el) => el.preselected);
            if (preselectedOption) this.package.entity_types.unshift(preselectedOption)
        },
    },
};


